.banner {
    position: relative;
    background-image: url('../../Assets/banner.png');
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-top: 5px solid #A37112;
    border-bottom: 5px solid #A37112;
  }
  
  .banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(14, 73, 55, 0.5);
    backdrop-filter: blur(5px);
  }
  
  .banner-logo {
    margin-left: 50px;
    width: 200px;
    z-index: 1;
  }
  
  .banner-slogan {
    color: white;
    font-size: 24px;
    z-index: 1;
  }

  .banner-slogan p {
    margin: 0;
    color: white; 
    font-size: 64px;
    font-weight: 500;
    z-index: 1; 
  }
  
  .banner-slogan p:nth-child(1) {
    padding-left: 0px;
  }
  
  .banner-slogan p:nth-child(2) {
    padding-left: 40px;
  }
  
  .banner-slogan p:nth-child(3) {
    padding-left: 80px;
  }
  
  .banner-slogan p {
    margin: 0;
  }
  