footer {
    background-color: #0E4937;
    color: white;
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-column h3 {
    margin-bottom: 10px;
}

.footer-column ul,
.footer-column p,
.social-media-links a {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-column ul li, .footer-column p, .social-media-links a {
    padding: 5px 0;
}

.footer-column ul li a {
    color: white;
    text-decoration: none;
    margin-bottom: 5px;
    padding: 10px 0;
}

.footer-column ul li a:hover {
    text-decoration: underline;
}

.footer-logo {
    max-width: 100px;
}

.social-media-links a {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.social-media-links i {
    margin-right: 5px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 14px;
}

.social-media-links a i.fa-facebook-f {
    background-color: #3b5998;
    color: white;
    opacity: 0.9;
}

.social-media-links a i.fa-instagram {
    background-color: #e4405f;
    color: white;
    opacity: 0.9;
}

.social-media-links a i.fa-whatsapp {
    background-color: #25D366;
    color: white;
    opacity: 0.9;
}

.social-media-links a:hover i.fa-facebook-f {
    background-color: #3b5998;
    opacity: 1;
}

.social-media-links a:hover i.fa-instagram {
    background-color: #e4405f;
    opacity: 1;
}

.social-media-links a:hover i.fa-whatsapp {
    background-color: #25D366;
    opacity: 1;
}

.footer-banner {
    margin-top: 30px;
}