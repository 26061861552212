header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #FFF;
}

.top-banner {
    background-color: #038C4C;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .contact-info a, .social-media a {
    margin: 0 10px;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
  
  .contact-info i, .social-media i {
    margin-right: 5px;
    background-color: white;
    border-radius: 50%; 
    padding: 5px;
    width: 20px; 
    height: 20px;
    display: flex; 
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 14px;
  }
  
  .social-media a i.fa-facebook-f { background-color: #3b5998; color: white; opacity: 0.9; }
  .social-media a i.fa-instagram { background-color: #e4405f; color: white; opacity: 0.9; }
  .social-media a i.fa-whatsapp { background-color: #25D366; color: white; opacity: 0.9; }
  
  .social-media a:hover i.fa-facebook-f { background-color: #3b5998; opacity: 1; }
  .social-media a:hover i.fa-instagram { background-color: #e4405f; opacity: 1; }
  .social-media a:hover i.fa-whatsapp { background-color: #25D366; opacity: 1; }

.header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #0E4937;
  }
  
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu li {
    margin-right: 20px;
  }
  
  .menu li a {
    text-decoration: none;
    color: white;
    font-weight: 500;
  }
  
  .menu li a:hover {
    text-decoration: underline;
  }
  
  .company-title {
    color: white;
    font-size: 24px;
    font-weight: 700;
  }