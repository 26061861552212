.background:has(> .detailed-service.right) {
  background-color: #f5f5f5;
  width: 100%;
}

.services-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailed-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  max-width: 95%;
  margin: 20px 0px;
  margin-left: auto;
  margin-right: auto;
}

.detailed-service .detailed-service-image {
  max-width: auto;
  min-width: 30%;
  max-height: 300px;
  margin: 0 20px;
}

.detailed-service .detailed-service-info {
  text-align: center;
  width: 70%;
}

.detailed-service-info h2 {
  margin-bottom: 10px;
}

.detailed-service-info ul {
  list-style-type: none;
  padding: 0;
}

.detailed-service-info li {
  margin-bottom: 5px;
}

.detailed-service.right {
  background-color: #f5f5f5;
}

.detailed-service.right .service-image {
  order: 2;
}

.detailed-service.left .service-image {
  order: 1;
}

.detailed-service.left .service-info, .detailed-service.right .service-info {
  order: 2;
}
