.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    padding-top: 30px;
    padding-bottom: 50px;
    margin: 0 auto;
}

.contact-form h3 {
    padding-bottom: 25px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button[type="submit"] {
    padding: 10px 15px;
    background-color: #0E4937;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #083d29;
}