.reviews-container {
    background: #f5f5f5;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .reviews-container h2 {
    margin-bottom: 30px;
  }
  
  .reviews-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .review-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: calc(33.333% - 20px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  
  .review-item h3 {
    color: #0E4937;
    margin-bottom: 5px;
  }
  
  .review-item p {
    color: #333;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    .review-item {
      width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .review-item {
      width: 100%;
    }
  }