.newsletter-container {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .newsletter-container form {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .newsletter-container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .newsletter-container button {
    cursor: pointer;
    padding: 10px 20px;
    background-color: #0E4937;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  input.error {
    border-color: red;
  }
  