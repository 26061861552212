.services-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px; 
    padding: 20px;
  }
  
  .service-item {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px; 
    text-align: center;
  }
  
  .service-image {
    max-width: 100%;
    width: 100px;
    height: auto;
    filter: invert(19%) sepia(13%) saturate(3132%) hue-rotate(115deg) brightness(98%) contrast(89%);
  }
  
  .service-btn {
    display: inline-block;
    margin-top: 10px;
    background-color: #0E4937;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
  }
  